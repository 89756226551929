import {Text as ChakraText, TextProps as ChakraTextProps} from '@chakra-ui/react'

export interface TextProps extends ChakraTextProps {
    mono?: boolean
}

export const Text = (props: TextProps) => {
    const {mono, ...rest } = props

    return (
        <ChakraText fontFamily={mono ? 'monospace' : 'inherit'} {...rest} />
    )
}
