import {Image as ChakraImage, ImageProps as ChakraImageProps} from '@chakra-ui/react'
import {useThemeContext} from "../context/ThemeContext";


interface ImageProps extends ChakraImageProps {
    hasBorder?: boolean
    isRounded?: boolean
}

export const Image = (props: ImageProps) => {
    const {colors} = useThemeContext()
    const {hasBorder, isRounded, ...rest} = props

    return (
        <ChakraImage borderRadius={isRounded ? 8 : 0} border={hasBorder ? `8px solid ${colors.secondary}` : 'none'} {...rest} />
    )
}
