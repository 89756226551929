import {createContext, ReactNode, useContext} from "react";
import {Cloudinary} from "@cloudinary/url-gen";

const cloudinary = new Cloudinary({cloud: {cloudName: "dzrcqoa2t"}, url: {secure: true}})
const CloudinaryContext = createContext<Cloudinary>(cloudinary)

export const CloudinaryProvider = ({children} : {children: ReactNode}) =>
    <CloudinaryContext.Provider value={cloudinary}>{children}</CloudinaryContext.Provider>

export const useCloudinary = () => {
    const cld = useContext<Cloudinary>(CloudinaryContext)
    if(!cld) throw Error("you must use cloudinary context inside cloudinary context provider")
    else return cld
}
