import {Link as ChakraLink, LinkProps as ChakraLinkProps} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import {routePageMap, SiteRoute} from "../util/const";
import {useThemeContext} from "../context/ThemeContext";
import {useAnalytics} from "../index";
import {useCallback} from "react";
import {logEvent} from "firebase/analytics";

export interface NavLinkProps extends ChakraLinkProps {
    to: SiteRoute
    selected?: boolean
    isMobile?: boolean
}


export const NavLink = (props: NavLinkProps) => {
    const {colors} = useThemeContext()
    const analytics = useAnalytics()

    const trackClick = useCallback(()=> {
        if(!props.selected) {
            const page = routePageMap.get(props.to)
            if(!page) return
            logEvent(analytics, "nav_link_clicked", {page})
        }
    }, [props.to, analytics, props.selected])

    return (
        <ChakraLink
            as={Link}
            {...props}
            color={colors.textOnPrimary}
            onClick={trackClick}
            fontSize={'18px'}
            h={'35px'}
            px={4}
            py={1}
            borderRadius={'45px'}
            fontFamily={'monospace'}
            textDecor={props.selected ? 'underline': 'none'}
            transition={'all 150ms ease-in-out'}
            _hover={{
                color: colors.secondary,
                textDecor: 'none'
            }}
        />
    )
}
