import React from 'react'
import {Box, Center, Code, Container, ListItem, SimpleGrid, Spacer, UnorderedList, VStack} from '@chakra-ui/react'
import {Heading, Image, Text, Link, Divider} from "../overrides";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {useContentWidth} from "../hooks/useContentWidth";
import {Helmet} from "react-helmet";

interface ProjectsProps {
    someProp?: string
}

export const Projects = (props: ProjectsProps) => {
    const width = useContentWidth()

    return (
        <Box w={width}>
            <Helmet>
                <title>Projects - Stephen S</title>
                <meta name={"description"} content={"OSS and closed-source projects and libraries I have developed"}/>
            </Helmet>
            <Container maxW={'container.lg'}>
                <VStack align={'left'} spacing={4}>
                    <Heading size={'2xl'}>Personal & Side Projects</Heading>
                    <Text>
                        Here are some Open & Closed-source projects and libraries I have built in my spare time.
                    </Text>
                    <Spacer h={'5em'}/>
                    <Heading size={'xl'}>
                        <Link href={'https://github.com/owl-93/Determinate-Progress-View'}>
                            Determinate Progress View (OSS) <ExternalLinkIcon mx={'2px'}/>
                        </Link>
                    </Heading>
                    <Center w={'full'}>
                        <Image
                            boxSize={{base: 'full', sm: '50%'}}
                            objectFit={'cover'}
                            p={4}
                            alt={'determinate progress view'}
                            isRounded
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/determinate_progress_view%2Fdpv.png?alt=media&token=0871063d-c182-4ee6-8f99-7f1c90338e47'}
                        />
                    </Center>
                    <Text>
                        This view is meant to be a highly customizable determinate progress view like the standard Android indeterminate progress bar.
                    </Text>
                    <Text>
                        It is built with Kotlin and supports heavy customization options and a convenient way to animate progress automatically.
                        You can use XML and Kotlin/Java functions to customize it. You can check out the very robust {' '}
                        <Link href={'https://github.com/owl-93/Determinate-Progress-View-Demo'} >demo app over here <ExternalLinkIcon mx="2px" /> </Link>
                        as well as some GIFs of the demo app below
                    </Text>
                    <SimpleGrid spacing={20} columns={[1, null, 3]}>
                        <Image width='full' borderRadius={4} src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/determinate_progress_view%2Fdpv_gif1.gif?alt=media&token=79a59dbc-9a61-4ffa-97b7-dea6d2b0e51b'}/>
                        <Image width='full' borderRadius={4} src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/determinate_progress_view%2Fdpv_gif2.gif?alt=media&token=abc679eb-8b14-48bd-a9a1-fa8b375bd1cb'}/>
                        <Image width='full' borderRadius={4} src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/determinate_progress_view%2Fdpv_gif3.gif?alt=media&token=84234346-9a1a-4293-abd8-b39a18f52ac0'}/>
                    </SimpleGrid>

                    <Divider py={'2em'}/>

                    <Heading size={'xl'}>
                        <Link href={'https://trackit.one'}>
                            Trackit (CS)<ExternalLinkIcon mx={'2px'}/>
                        </Link>
                    </Heading>
                    <Center w={'full'}>
                        <Image alt={'trackit desktop screenshot'}
                               m={4}
                               borderRadius={4}
                               src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/trackit%2FScreen%20Shot%202021-10-11%20at%2011.49.48%20AM.png?alt=media&token=2ce38ce1-558e-461c-8505-52629d8233c7'}/>
                    </Center>
                    <Center w={'full'}>
                        <Image alt={'trackit mobile screenshot'}
                               m={4}
                               borderRadius={4}
                               src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/trackit%2FScreen%20Shot%202021-10-11%20at%2011.50.11%20AM.png?alt=media&token=8ffa22fb-ffd4-47d4-863d-c6e6700df799'}/>
                    </Center>
                    <Text>
                        Trackit is meant to be a all-in-one parcel tracking app. It has SSO support for logging in with other auth providers like Google, Facebook,
                        as well as email/password authentication. It's very much still a work in progress. It has decent mobile support for a more native app feel
                        when used on a mobile device.
                    </Text>
                    <SimpleGrid columns={[1, 2]} spacing={20}>
                        <Box>
                            <Heading size={'lg'}>Features</Heading>
                            <UnorderedList>
                                <ListItem>Automatic courier detection</ListItem>
                                <ListItem>Tracking details and progress log for each parcel</ListItem>
                                <ListItem>Parcel nicknames for easy tracking experience</ListItem>
                                <ListItem>Courier override for if detection doesn't work</ListItem>
                                <ListItem>Multi-federated SSO via Google, Facebook, or email/password</ListItem>
                                <ListItem>First-class mobile browser support</ListItem>
                                <ListItem>Easy access for external courier tracking page for each parcel</ListItem>
                                <ListItem>Dark/Light mode theme</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading size={'lg'}>Planned Features</Heading>
                            <UnorderedList>
                                <ListItem>Browser extension for quickly or automatically adding tracking numbers from emails, amazon, shopify, etc</ListItem>
                                <ListItem>Bulk parcel adding</ListItem>
                                <ListItem>Auto-archiving delivered parcels</ListItem>
                                <ListItem>(Mobile) adding tracking numbers by use of camera API and OCR</ListItem>
                                <ListItem>UI and layout improvements</ListItem>
                            </UnorderedList>
                        </Box>
                    </SimpleGrid>


                    <Divider py={'2em'}/>

                    <Heading size={'xl'}>
                        <Link href={'https://github.com/owl-93/porter'}>
                            Porter (OSS)<ExternalLinkIcon mx={'2px'}/>
                        </Link>
                    </Heading>
                    <Center w={'full'}>
                        <Image
                            m={4}
                            borderRadius={4}
                            alt={'porter screenshot 1'}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/porter%2FScreen%20Shot%202021-10-11%20at%2012.26.39%20PM.png?alt=media&token=257ae0f0-5ee2-47fc-a9a9-38df6d8208f6'} />
                    </Center>
                    <Text>
                        Porter is a small electron-based task bar application that uses the unix <Code>lsof</Code> command to identify occupied ports on your system
                        and lists out the applications that are utilizing those ports. It uses electron IPC to keep the port data updated between the running service
                        and the UI. It originally started as a python script to list the port data in a more user-friendly way in a terminal, but quickly evolved to
                        a GUI application built in electron.
                    </Text>
                    <Text>
                        I used electron for speed of development and wanted cross platform support for Unix based OS's. I welcome any and all PR's that add windows
                        support as well. Originally, I was exposing the port data via the python script which I converted to a Flask web server that served that data,
                        and then re-wrote the service and webserver in node and express. I then realized REST was overkill and clunky for this use case and just moved on
                        to using IPC since the application was using electron anyway.
                    </Text>
                    <Text>
                        It also has a dark theme :)
                    </Text>

                    <SimpleGrid w={'full'} spacing={20} columns={[1, 2]}>
                        <Image
                            alt={'porter screenshot 2'}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/porter%2FScreen%20Shot%202021-10-11%20at%2012.09.14%20PM.png?alt=media&token=91e72299-9730-461e-9274-69c88e5ee424'}
                        />
                        <Image
                            alt={'porter screenshot 3'}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/porter%2FScreen%20Shot%202021-10-11%20at%2012.09.54%20PM.png?alt=media&token=0175231c-927c-4e14-b1fb-8fa98f837342'}
                        />
                    </SimpleGrid>

                    <SimpleGrid columns={[1, 2]} spacing={20}>
                        <Box>
                            <Heading size={'lg'}>Features</Heading>
                            <UnorderedList>
                                <ListItem>Quick viewing of user friendly process name</ListItem>
                                <ListItem>Outputs multiple ports per process if utilized</ListItem>
                                <ListItem>Shows the PID for the process occupying ports</ListItem>
                                <ListItem>Light/Dark Theme</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading size={'lg'}>Planned Features</Heading>
                            <UnorderedList>
                                <ListItem>Kill button to quit a process</ListItem>
                                <ListItem>Better electron support (removing icon, hotkeys, etc)</ListItem>
                                <ListItem>Filter information by port range</ListItem>
                                <ListItem>System notifications for when a port become occupied</ListItem>
                            </UnorderedList>
                        </Box>
                    </SimpleGrid>

                    <Divider py={'2em'}/>

                    <Heading size={'xl'}>
                        <Link href={'https://github.com/owl-93/snapper'}>
                            Snapper (OSS)<ExternalLinkIcon mx={'2px'}/>
                        </Link>
                    </Heading>
                    <Center w={'full'}>
                        <Image
                            p={4}
                            alt={'snapper logo'}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/snapper%2Fsnapper.png?alt=media&token=76779fa8-8b62-4fb8-9bc8-32517bbd07c6'} />
                    </Center>
                    <Text>
                        Snapper is a web microservice build in Golang to capture and cache website opengraph data. Check out the Readme on github
                        for more details
                    </Text>

                    <Center w={'full'}>
                        <Image
                            p={4}
                            alt={'snapper screenshot'}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/snapper%2FScreen%20Shot%202021-10-25%20at%2012.18.58%20PM.png?alt=media&token=85093ebe-b98c-4b25-951d-9e3daaa25b23'} />
                    </Center>
                    <SimpleGrid columns={[1, 2]} spacing={20}>
                        <Box>
                            <Heading size={'lg'}>Features</Heading>
                            <UnorderedList>
                                <ListItem>Multiple response formats available</ListItem>
                                <ListItem>Lightning fast caching backed by Redis</ListItem>
                                <ListItem>Configurable cache TTL</ListItem>
                                <ListItem>Option to force a refresh at the request level</ListItem>
                                <ListItem>Option to globally disable the cache entirely</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading size={'lg'}>Planned Features</Heading>
                            <UnorderedList>
                                <ListItem>Support for batching and concurrency</ListItem>
                                <ListItem>Adding more configuration options</ListItem>
                                <ListItem>Support for more than just Opengraph data</ListItem>
                                <ListItem>Ability to capture a page screenshot if no og:image available</ListItem>
                            </UnorderedList>
                        </Box>
                    </SimpleGrid>

                    <Divider py={'2em'}/>

                    <Heading size={'xl'}>
                        <Link href={'https://github.com/owl-93/themed-browser-bar'}>
                            Themed Browser Bar (OSS)<ExternalLinkIcon mx={'2px'}/>
                        </Link>
                    </Heading>
                    <Center w={'full'}>
                        <Image
                            m={4}
                            borderRadius={4}
                            alt={'themed browser bar'}
                            width={['full', '50%']}
                            src={'https://firebasestorage.googleapis.com/v0/b/theschmidt-49cc1.appspot.com/o/themed-browser-bar%2FScreen_Recording_20211025-161750_Chrome_1_1.gif?alt=media&token=cf5d0e06-39da-4b18-8551-6bc50f9e9366'} />
                    </Center>
                    <Text>
                        Themed browser bar is a tiny react library that provides a hook to dynamically update your websites theme-color meta tag
                        in the header. This allows your site to provide a theme color that some mobile browsers support as part of their UI. See (chrome on android)
                        Typically, this is useful to get the native system UI on a mobile browser to feel more integrated with your application or website
                        by adopting a similar color theme or style to your application. (this site uses it!)
                    </Text>
                    <Text>
                        It is built in Typescript and the types are bundled with the package so no need to install types separately
                    </Text>
                    <Text>
                        You can find the package on NPM <Link href={'https://www.npmjs.com/package/@owl-93/themed-browser-bar'} isExternal>here <ExternalLinkIcon/></Link>
                    </Text>
                    <SimpleGrid columns={[1, 2]} spacing={20}>
                        <Box>
                            <Heading size={'lg'}>Features</Heading>
                            <UnorderedList>
                                <ListItem>Implemented as a hook for modern FC support</ListItem>
                                <ListItem>A tiny package with no dependencies other than React</ListItem>
                                <ListItem>Updates existing meta tags or adds a new one if required</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading size={'lg'}>Planned Features</Heading>
                            <UnorderedList>
                                <ListItem>None at this time, but please feel free to create an issue or pull request</ListItem>
                            </UnorderedList>
                        </Box>
                    </SimpleGrid>
                </VStack>
            </Container>
        </Box>
    )
}
