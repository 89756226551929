import {ColorMode, ThemeColors} from './themes'

export const atlantisDawn: ThemeColors = {
    primary: '#023047',
    secondary: '#FB8500',
    background: '#3a7f93',
    tertiary: '#FFB703',
    text: '#0a0813',
    textOnPrimary: '#FFB703',
    textOnSecondary: '#25272c',
    textOnTertiary: '#25272c'
}

export const atlantisDay: ThemeColors = {
    primary: '#03496c',
    secondary: '#f39b38',
    background: '#98d4e2',
    tertiary: '#efb729',
    text: '#0a0813',
    textOnPrimary: '#efb729',
    textOnSecondary: '#0a0813',
    textOnTertiary: '#25272c'
}

export const atlantisNoon: ThemeColors = {
    primary: '#0299e3',
    secondary: '#FFB703',
    background: '#dff7fd',
    tertiary: '#FFB703',
    text: '#0a0813',
    textOnPrimary: '#133e2d',
    textOnSecondary: '#0a0813',
    textOnTertiary: '#25272c'
}

export const atlantisDusk: ThemeColors = {
    primary: '#03496c',
    secondary: '#8851b4',
    background: '#0e1d1f',
    tertiary: '#FB8500',
    text: '#F5DECC',
    textOnPrimary: '#349752',
    textOnSecondary: '#dff7fd',
    textOnTertiary: '#25272c'
}

export const atlantisNight: ThemeColors = {
    primary: '#042366',
    secondary: '#8851b4',
    background: '#060310',
    tertiary: '#f59e3d',
    text: '#66d487',
    textOnPrimary: '#FFB703',
    textOnSecondary: '#dff7fd',
    textOnTertiary: '#25272c'
}


export const atlantisModes: Map<ColorMode, ThemeColors> = new Map([
    ['dawn', atlantisDawn],
    ['day', atlantisDay],
    ['noon', atlantisNoon],
    ['dusk', atlantisDusk],
    ['night', atlantisNight],
])
