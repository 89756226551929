import React, {useMemo} from 'react'
import {
    Box, Center,
    Container,
    ListItem,
    SimpleGrid, Spacer,
    UnorderedList,
    VStack
} from '@chakra-ui/react'
import {Text, Image, Heading} from '../overrides'
import {useIsMobile} from "../hooks/useIsMobile";
import {useContentWidth} from "../hooks/useContentWidth";
import {useCloudinary} from "../context/CloudinaryContext";
import {scale} from "@cloudinary/url-gen/actions/resize";
import {Helmet} from 'react-helmet'


interface HomeProps {
    someProp?: string
}


export const Home = (props: HomeProps) => {
    const cld = useCloudinary()
    const isMobile = useIsMobile()
    const width = useContentWidth()

    const profilePhoto = useMemo(() => {
        return cld?.image('Cover/profile_l8cp84').resize(scale(500, 500))
    }, [cld])

    return (
        <Box w={width}>
            <Helmet>
                <title>Welcome! - Stephen S</title>
                <meta name={"description"} content={"Homepage"}/>
            </Helmet>
            <Container maxW={'container.lg'}>
                <Heading mt={2} as={'h1'} size={'2xl'} w='full' textAlign={'center'}>Nice to meet you! </Heading>
                <VStack w={'full'} my={10}>
                    <Center w={{base: '100%', sm: '66%'}} >
                        <Image src={profilePhoto.toURL()} borderRadius={25}/>
                    </Center>
                    <Text fontSize={'12px'} textStyle={'italic'}>Moab, UT</Text>
                </VStack>

                <VStack align={'left'} spacing={4}>
                    <Heading as={'h1'} size={'xl'}>I'm Stephen.</Heading>
                    <Text>Thanks for visiting my website. Please check out my github or linkedin profile or drop me an
                        email using the buttons {isMobile ? "in the menu" : "to the left"}.
                    </Text>

                    <Spacer h='2em'/>

                    <Heading variant={'h3'}>A Bit About Me</Heading>
                    <Heading variant={'h4'} size={'md'}>Background, Education & Experience</Heading>
                    <Text> I am a passionate full-stack software engineer that loves to build things. </Text>
                    <Text> I have a Bachelor's in Computer Science from Cal Poly San Luis Obispo.
                        Most of my work experience is in Native
                        Android development with Kotlin, and Web development
                        using React with Typescript (reluctantly also in JS when forced).
                    </Text>
                    <Text>
                        I also have a lot of experience in backend development using the Spring framework,
                        Node, and others.
                    </Text>
                    <Text> I'm a highly experienced Java developer, although I prefer to avoid
                        Java these days when possible. I am also very proficient in
                        Python, JS scripting, and can be bothered to write in C when needed. Currently,
                        I'm working on teaching myself backend development in Go, as well as learning Swift.
                    </Text>
                    <Text> My full experience & skills are listed at the bottom of the page.</Text>

                    <Heading variant={'h4'} size={'md'}>Hobbies & Interests</Heading>
                    <Text>
                        Outside of work, my hobbies include more programming (for learning and fun), cooking,
                        serious BBQing, gardening, offroading & camping, shooting, and a little bit of investing.
                        I love sci-fi novels & movies, and lately I've been trying my hand at breadmaking.
                    </Text>
                    <Text>I have a serious homemade hot sauce hobby that I use my home grown peppers for, and I will
                        shamelessly claim that some of my hot sauces are the best I've ever had. My wife graciously
                        tolerates this hobby, as it often results in the house being fumigated with aerosolized
                        capsaicin.
                    </Text>
                    <Text>
                        In addition to my wife, I live with 2 very needy senior cats
                    </Text>

                    <Spacer h='2em'/>

                    <Heading variant={'h2'} as={'h2'} size={'xl'}>Experience & Skills</Heading>
                    <SimpleGrid columns={[1, 2]} spacing={'20px'}>
                        <Box>
                            <Heading as={'h3'} size={'md'}>Languages & DB's</Heading>
                            <UnorderedList>
                                <ListItem>Kotlin</ListItem>
                                <ListItem>Java</ListItem>
                                <ListItem>Typescript/Javascript</ListItem>
                                <ListItem>Python</ListItem>
                                <ListItem>C</ListItem>
                                <ListItem>MongoDB</ListItem>
                                <ListItem>Redis</ListItem>
                                <ListItem>mySQL/SQLite/SQL Server</ListItem>
                                <ListItem>Firestore</ListItem>
                                <ListItem>Room</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading as={'h3'} size={'md'}>Frameworks & Platforms</Heading>
                            <UnorderedList>
                                <ListItem>Android</ListItem>
                                <ListItem>React</ListItem>
                                <ListItem>Spring</ListItem>
                                <ListItem>Electron</ListItem>
                                <ListItem>Express</ListItem>
                                <ListItem>Flask</ListItem>
                                <ListItem>Microsoft Add-in SDK</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading as={'h3'} size={'md'}>Tools & Technologies</Heading>
                            <UnorderedList>
                                <ListItem>Kubernetes/VMware Tanzu</ListItem>
                                <ListItem>Docker</ListItem>
                                <ListItem>Gitlab CI/CD</ListItem>
                                <ListItem>Heroku/CloudFoundry</ListItem>
                                <ListItem>Firebase</ListItem>
                                <ListItem>Storybook</ListItem>
                                <ListItem>Mockito</ListItem>
                                <ListItem>Websocket</ListItem>
                                <ListItem>Apache Kafka</ListItem>
                                <ListItem>Redis Pub/Sub</ListItem>
                                <ListItem>RabbitMQ/AMQP</ListItem>
                                <ListItem>MQTT</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box>
                            <Heading as={'h3'} size={'md'}>Misc</Heading>
                            <UnorderedList>
                                <ListItem>Figma</ListItem>
                                <ListItem>Miro</ListItem>
                                <ListItem>JIRA/Agile</ListItem>
                                <ListItem>Gitlab</ListItem>
                                <ListItem>Postman</ListItem>
                                <ListItem>Storybook</ListItem>
                                <ListItem>Mockito</ListItem>
                            </UnorderedList>
                        </Box>
                    </SimpleGrid>


                </VStack>

            </Container>

        </Box>
    )
}

