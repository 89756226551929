import React, {createContext, useContext} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ChakraProvider} from "@chakra-ui/react";
import {ThemeContextProvider} from "./context/ThemeContext";
import {theme} from "./util/theme";
import {CloudinaryProvider} from "./context/CloudinaryContext";
import {QueryClientProvider} from "react-query";
import {queryClient} from "./api/queries";
import {ReactQueryDevtools} from "react-query/devtools";
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBZB77jt-e4pJF7s-L6iUEYfkevzDGHG0w",
    authDomain: "theschmidt-49cc1.firebaseapp.com",
    projectId: "theschmidt-49cc1",
    storageBucket: "theschmidt-49cc1.appspot.com",
    messagingSenderId: "573575194487",
    appId: "1:573575194487:web:bbb3632df5a6d0d4086372",
    measurementId: "G-FTSGPVPPLP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const AnalyticsContext = createContext(analytics)

export const useAnalytics = () => {
    const analytics = useContext(AnalyticsContext)
    if (!analytics) throw Error("analytics context must be used from within analytics context provider")
    return analytics
}

ReactDOM.render(
    <React.StrictMode>
        <AnalyticsContext.Provider value={analytics}>
            <ChakraProvider theme={theme}>
                <ThemeContextProvider>
                    <CloudinaryProvider>
                        <QueryClientProvider client={queryClient}>
                            <App/>
                            <ReactQueryDevtools/>
                        </QueryClientProvider>
                    </CloudinaryProvider>
                </ThemeContextProvider>
            </ChakraProvider>
        </AnalyticsContext.Provider>

    </React.StrictMode>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
