import {Box,  Wrap} from "@chakra-ui/react";
import {Tag} from '../overrides'
import {useThemeContext} from "../context/ThemeContext";
import {ThemeColors} from "../util/themes";
import {useIsMobile} from "../hooks/useIsMobile";

interface TagSelectorProps {
    tags: Set<string>
    selected: Set<string>
    onTagSelect: (tag: string) => void
    selectAll: () => void
}

const getTagStyle = (selected: boolean, colors: ThemeColors) => ({
    borderRadius: 'full',
    px: 3,
    py: 1,
    bg: selected ? colors.primary : colors.background,
    color: selected ? colors.textOnPrimary : colors.text,
    border: `2px solid ${colors.primary}`,
    _hover: {
        color: selected ?  colors.textOnPrimary : colors.secondary
    }
})

export const TagSelector = (props: TagSelectorProps) => {
    const {colors} = useThemeContext()
    const isMobile = useIsMobile()

    return (
        <Box pt={'80px'} position={'sticky'} top={0} w={'full'} mx={['25px', '50px', '100px']}>
            <Wrap w={'full'} spacing={4} justify={'center'} pb={4}
                  backdropFilter="blur(2px)"
                  backgroundBlendMode="overlay">
                <Tag size={isMobile ? 'sm' : 'md'} onClick={props.selectAll}
                     {...getTagStyle(props.selected.size === props.tags.size, colors)}
                >
                    all
                </Tag>
                {Array.from(props.tags).map((tag: string, idx: number) => {
                    return <Tag key={`${tag}-${idx}`} size={isMobile ? 'sm' : 'md'}
                                {...getTagStyle(props.selected.has(tag), colors)}
                                onClick={() => props.onTagSelect(tag)}
                    >
                        {tag}
                    </Tag>
                })}
            </Wrap>
        </Box>

    )
}
