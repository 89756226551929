import {Divider as ChakraDivider, DividerProps as ChakraDividerProps} from '@chakra-ui/react'
import {useThemeContext} from "../context/ThemeContext";

export type DividerProps = ChakraDividerProps

export const Divider = (props: DividerProps) => {
  const {colors}  = useThemeContext()

    return (
        <ChakraDivider borderColor={colors.tertiary} {...props} />
    )
}
