import {
    Grid,
    GridItem,
    Spinner,
    useBreakpointValue,
    VStack,
    Portal, Center
} from "@chakra-ui/react";
import {Heading, Image, Text} from "../overrides";
import {useCloudinary} from "../context/CloudinaryContext";
import {AdvancedImage, lazyload} from "@cloudinary/react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useQuery} from "react-query";
import {getPhotosQuery, getPhotoTagsQuery} from "../api/queries";
import {TagSelector} from "../components/TagSelector";
import {CloudinaryResource} from "../model/api";
import {hasTags} from "../util/cloudinary";
import {fill } from "@cloudinary/url-gen/actions/resize";
import {Helmet} from "react-helmet";
//import {useThemeContext} from "../context/ThemeContext";

interface PhotosProps {

}

export const Photos = (props: PhotosProps) => {
    //const {colors} = useThemeContext()
    const cld = useCloudinary()
    const cImage = cld.image('Cover/utah_panorama')
    const [tags, setTags] = useState<Set<string>>(new Set())
    const tagsQuery = useQuery(getPhotoTagsQuery())
    const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set())
    const photosQuery = useQuery(getPhotosQuery())
    const columns = useBreakpointValue({base: 1, xs: 1, sm: 2, md: 3, lg: 4 })
    const [lightboxImage, setLightboxImage] = useState<CloudinaryResource | null>(null)

    useEffect(() => {
        if(tagsQuery.data) {
            setTags(new Set(tagsQuery.data))
            setSelectedTags(new Set(tagsQuery.data))
        }
    }, [tagsQuery.data])

    const onTagSelect = useCallback((selected: string) => {
        setSelectedTags((prevTags: Set<string>) => {
            const newTags = new Set(prevTags)
            newTags.has(selected) ? newTags.delete(selected) : newTags.add(selected)
            return newTags
        })
    }, [])

    const selectAll = useCallback(() => {
        setSelectedTags(tags)
    }, [tags])

    const photos: CloudinaryResource[] = useMemo(() => {
        const results = photosQuery.data?.filter((photo: CloudinaryResource) => hasTags(selectedTags, photo))
        //console.log(selectedTags, ' - filter results: ', results)
        return results ?? []
    }, [photosQuery.data, selectedTags])

    useEffect(() => {
        if(process.env.NODE_ENV === 'development') {
            photosQuery.data?.forEach((photo: CloudinaryResource) => {
                if (photo.tags?.length === 0) console.log(`photo ${photo.public_id} is untagged`)
            })
        }
    }, [ photosQuery.data])

    const lightboxContent = useMemo(() => {
        return lightboxImage ? cld.image(lightboxImage?.public_id).toURL() : undefined
    }, [cld, lightboxImage])


    return (
        <VStack spacing={3} w={'full'} >
            <Helmet>
                <title>Photos - Stephen S</title>
                <meta name={"description"} content={"Photos from my home life and hobbies"}/>
            </Helmet>
            <Heading size={'2xl'}>Photo Gallery</Heading>
            <AdvancedImage cldImg={cImage}/>
            <Text fontSize={12}>Arches National Park, UT</Text>
            {tagsQuery.isLoading ? <Spinner/> :
                <TagSelector tags={tags} onTagSelect={onTagSelect} selected={selectedTags} selectAll={selectAll}/>
            }

            <Grid templateColumns={`repeat(${columns}, 1fr)`} templateRows={'masonry'} gap={3} >
                {photos.map((r: CloudinaryResource) => {
                    const image = cld.image(r.public_id)
                    const isLandscape = r.width > r.height
                    if(isLandscape) image.resize(fill().width(600).height(400))
                    else image.resize(fill().width(600).height(800))
                    return (
                        <GridItem key={r.asset_id} rowSpan={isLandscape ? 1 : 2} display={'flex'}
                                  onClick={() => setLightboxImage(r)}>
                            <AdvancedImage cldImg={image} plugins={[lazyload()]} />
                        </GridItem>
                    )
                })}
            </Grid>

            {lightboxImage && <Portal>
                <Center position={'fixed'} top={0} left={0} bottom={0} right={0} padding={[2, 10 , 20]}
                     w={'100vw'} h={'100vh'} bg={'rgba(0, 0, 0, .75)'}
                     onClick={() => setLightboxImage(null)}
                >
                    {lightboxContent ?
                        <Image src={lightboxContent}  maxW='full' maxH='full' objectFit={'cover'} />
                        : <Spinner/>}
                </Center>
            </Portal>}

            {/*<Box w={'full'} className="powr-media-gallery" id="c899f305_1634227922"/>*/}
        </VStack>
    )
}
