import {extendTheme} from "@chakra-ui/react";


export const theme = extendTheme({
    // styles: {
    //     global: (props) => ({
    //         body: {
    //             transition: 'background-color 200ms ease-in-out, color 200ms ease-in-out'
    //         },
    //     })
    // },

})
