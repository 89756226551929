import {ColorMode, ThemeColors} from "./themes";

export const arrakisDawn: ThemeColors = {
    primary: '#5C271E',
    secondary: '#529fb7',
    background: '#cbc099',
    tertiary: '#c16b1f',
    text: '#111113',
    textOnPrimary: '#FBF9EF',
    textOnSecondary: '#FBF9EF',
    textOnTertiary: '#FBF9EF'
}

export const arrakisDay: ThemeColors = {
    primary: '#8a392a',
    secondary: '#c16b1f',
    background: '#f3e9ba',
    tertiary: '#529fb7',
    text: '#25272c',
    textOnPrimary: '#fcfaf1',
    textOnSecondary: '#fcfaf1',
    textOnTertiary: '#25272c'
}

export const arrakisNoon: ThemeColors = {
    primary: '#D25437',
    secondary: '#8cd0e8',
    background: '#FDFAEC',
    tertiary: '#7BBACC',
    text: '#27292b',
    textOnPrimary: '#FDFAEC',
    textOnSecondary: '#27292b',
    textOnTertiary: '#27292b'
}

export const arrakisDusk: ThemeColors = {
    primary: '#693A11',
    secondary: '#4E2018',
    background: '#152226',
    tertiary: '#c16b1f',
    text: '#FCFAF1',
    textOnPrimary: '#FCFAF1',
    textOnSecondary: '#FCFAF1',
    textOnTertiary: '#18323A'
}

export const arrakisNight: ThemeColors = {
    primary: '#722903',
    secondary: '#2F140E',
    background: '#0a1018',
    tertiary: '#0C191D',
    text: '#F3EDCE',
    textOnPrimary: '#F3EDCE',
    textOnSecondary: '#F3EDCE',
    textOnTertiary: '#F3EDCE'
}

export const arrakisModes : Map<ColorMode, ThemeColors> = new Map([
    ['dawn', arrakisDawn],
    ['day', arrakisDay],
    ['noon', arrakisNoon],
    ['dusk', arrakisDusk],
    ['night', arrakisNight]
])
