import {Heading as ChakraHeading, HeadingProps as ChakraHeadingProps} from '@chakra-ui/react'

export type HeadingProps = ChakraHeadingProps

export const Heading = (props: HeadingProps) => {

    return (
        <ChakraHeading {...props} />
    )
}
