import React, {Context, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo, useReducer} from "react";
import {ColorMode, SiteTheme, siteThemes, ThemeColors} from "../util/themes";
import {arrakisDusk} from "../util/themes.arrakis";
import {useLocalStorage} from "react-use";
import {GiLeechingWorm, GiSpiderWeb, GiGiantSquid} from "react-icons/gi";

interface ThemeConfig {
    colors: ThemeColors,
    mode: ColorMode,
    theme: SiteTheme,
    icon: ReactElement
}

const defaultConfig: ThemeConfig = {
    colors: arrakisDusk,
    mode: "dusk",
    theme: 'arrakis',
    icon: <GiLeechingWorm/>
}

interface ThemeContextResult extends ThemeConfig {
    setTheme: (theme: SiteTheme) => void
    setMode: (mode: ColorMode) => void
}

const ThemeContext: Context<ThemeContextResult> = React.createContext({
    ...defaultConfig,
    setTheme: (t: SiteTheme) => {},
    setMode: (m: ColorMode) => {}
})

interface SiteThemeProviderProps {
    children: ReactNode
}

type ThemeAction = 'SET_THEME' | 'SET_MODE'
type ThemeActionPayload = SiteTheme | ColorMode

interface ThemeEvent {
    type: ThemeAction
    payload: ThemeActionPayload
}

const getIcon = (theme: SiteTheme): ReactElement => {
    switch (theme) {
        case "arrakis":
            return <GiLeechingWorm/>
        case "mirkwood":
            return <GiSpiderWeb />
        case "atlantis":
            return <GiGiantSquid />
    }
}



const THEME_KEY = 'theme'
const MODE_KEY = 'mode'
const reducer =(current: ThemeConfig, event: ThemeEvent): ThemeConfig => {
    let theme: SiteTheme = current.theme
    let mode : ColorMode = current.mode
    switch (event.type) {
        case "SET_THEME":
            theme = (event.payload as SiteTheme)
            break
        case "SET_MODE":
            mode = (event.payload as ColorMode)
            break;
        default:
            console.warn(`unsupported theme event: ${event.type}`)
    }
    if(theme && mode) {
        let colors = siteThemes.get(theme)?.get(mode)
        let icon = getIcon(theme)
        return colors ? {theme, mode, colors, icon} : current
    }else return current
}

export const ThemeContextProvider = ({children}: SiteThemeProviderProps) => {
    const [storedTheme, setStoredTheme] = useLocalStorage<SiteTheme>(THEME_KEY, 'arrakis')
    const [storedMode, setStoredMode] = useLocalStorage<ColorMode>(MODE_KEY, 'night')
    const [state, dispatch] = useReducer(reducer, defaultConfig)

    const setTheme = useCallback((theme: SiteTheme) => {
        setStoredTheme(theme)
        dispatch({type: 'SET_THEME', payload: theme})
    }, [dispatch, setStoredTheme])

    const setMode = useCallback((mode: ColorMode) => {
        setStoredMode(mode)
        dispatch({type: 'SET_MODE', payload: mode})
    }, [dispatch, setStoredMode])

    useEffect(() => {
        if(storedTheme && storedMode) {
            setTheme(storedTheme)
            setMode(storedMode)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const value: ThemeContextResult = useMemo(() => ({...state, setTheme, setMode}), [state, setTheme, setMode])

    return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => {
    const theme = useContext(ThemeContext)
    if(!theme) console.warn("You must call useThemeContext within a ThemeContext.Provider")
    return theme
}

