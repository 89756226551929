import {arrakisModes} from "./themes.arrakis";
import {mirkwoodModes} from "./themes.mirkwood";
import {atlantisModes} from "./themes.atlantis";

export interface ThemeColors {
    primary: string,
    secondary: string,
    background: string
    tertiary: string,
    text: string,
    textOnPrimary: string
    textOnSecondary: string
    textOnTertiary: string
}

export type ColorMode = 'dawn' | 'day' | 'noon' | 'dusk' | 'night'

export type SiteTheme = "arrakis" | 'atlantis' | 'mirkwood'

export const siteThemes: Map<SiteTheme, Map<ColorMode, ThemeColors>> = new Map([
    ['arrakis', arrakisModes],
    ['mirkwood', mirkwoodModes],
    ['atlantis', atlantisModes]
])
