import React, {ReactElement, useCallback, useMemo} from 'react'
import {HStack, IconButton, Select, StackProps, VStack} from '@chakra-ui/react'
import {BsSunrise, BsSun, BsSunFill, BsSunset, BsMoon} from "react-icons/bs";
import {useThemeContext} from "../context/ThemeContext";
import {ColorMode, SiteTheme, siteThemes} from "../util/themes";
import {useAnalytics} from "../index";
import {logEvent} from "firebase/analytics";

type ColorModeToggleProps = StackProps

export const ThemeControls = (props: ColorModeToggleProps) => {
    const {colors, theme, mode, setTheme, icon} = useThemeContext()
    const analytics = useAnalytics()

    const handleThemeChange = useCallback((newTheme: string) => {
        if(newTheme !== theme) {
            setTheme(newTheme as SiteTheme)
            logEvent(analytics, "set_theme", {theme: newTheme, mode})
        }
    }, [theme, setTheme, mode, analytics])


    return (
        <VStack {...props} width={'min-content'} onClick={e => e.stopPropagation()} p={2}>
            <Select color={colors.textOnPrimary}
                    icon={icon}
                    borderColor={colors.textOnPrimary}
                    fontFamily={'monospace'}
                    value={theme}
                    onChange={e => handleThemeChange(e.target.value)}
            >
                {Array.from(siteThemes.keys()).map((theme: SiteTheme) =>
                    <option style={{textTransform: 'capitalize'}} key={theme} value={theme}>{theme}</option>
                )}
            </Select>
            <HStack onClick={e => e.stopPropagation()} >
                {modes.map((props: ThemeModeIconProps) => <ThemeModeIcon key={props.mode} {...props} /> )}
            </HStack>
        </VStack>

    )
}

interface ThemeModeIconProps {
    mode: ColorMode
    icon: ReactElement
}

const modes: ThemeModeIconProps[] = [
    {mode: 'dawn', icon: <BsSunrise/>},
    {mode: 'day', icon: <BsSun/>},
    {mode: 'noon', icon: <BsSunFill/>},
    {mode: 'dusk', icon: <BsSunset/>},
    {mode: 'night', icon: <BsMoon/>}
]

const ThemeModeIcon = (props: ThemeModeIconProps) => {
    const {colors, mode, theme, setMode} = useThemeContext()
    const analytics = useAnalytics()

    const selected = useMemo(() => {
        return props.mode === mode
    }, [props.mode, mode])

    const onModeSelect = useCallback(() => {
        if(mode !== props.mode) { //only trigger a callback if we're changing modes
            setMode(props.mode)
            logEvent(analytics, "set_theme", {theme, mode: props.mode})
        }
    }, [setMode, props.mode, mode, theme, analytics])


    return (
        <IconButton
            aria-label={props.mode}
            icon={props.icon}
            onClick={onModeSelect}
            variant={'ghost'}
            borderRadius={'100px'}
            bg={selected ? colors.textOnPrimary : colors.primary}
            color={selected ? colors.primary : colors.textOnPrimary}
            transition={'all 200ms ease'}
            _hover={{bg: colors.secondary, color: colors.textOnSecondary}}
        />
    )
}
