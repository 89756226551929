import {
    Box,
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerContent,
    Flex,
    IconButton,
    useDisclosure,
    VStack
} from "@chakra-ui/react";
import {Page, routePageMap, SiteRoute} from "../util/const";
import {NavLink} from "./NavLink";
import {useLocation} from "react-router";
import {useIsMobile} from "../hooks/useIsMobile";
import {Link as RouterLink} from 'react-router-dom'

import {FaAngleDown, FaAngleUp} from "react-icons/fa";
import React, {ReactElement, ReactNode, useMemo} from "react";
import {ThemeControls} from "./ThemeControls";
import {useThemeContext} from "../context/ThemeContext";
import {ThemePopover} from "./ThemePopover";
import {MobileTray} from "./Tray";
import {Divider} from '../overrides'

interface NavbarProps {
    height?: number
}


export const Navbar = (props: NavbarProps) => {
    const location = useLocation()
    const isMobile = useIsMobile()
    const { colors } = useThemeContext()

    const page: Page | undefined = useMemo(() => {
        return routePageMap.get(location.pathname as SiteRoute) ?? routePageMap.get(SiteRoute.HOME)
    }, [location.pathname])

    const navLinks = useMemo(() => {
        const links: ReactElement[] = []
        routePageMap.forEach((sitePage: Page, route: SiteRoute) => {
            links.push(<NavLink key={route} as={RouterLink} to={route} color={colors.tertiary}
                                selected={page === sitePage}>{sitePage}</NavLink>)
        })
        return links
    }, [page, colors.tertiary])

    if(!page) return <div/>
    return (
        <Box w={'full'} position={'sticky'} top={0} bg={colors.primary} transitionDuration={'normal'} grow={0} zIndex={1}>
            <Flex w='full' minH="72px" justify={'space-evenly'} align={'center'} padding={2} >
                {isMobile ? <MobileNav page={page} navLinks={navLinks} />: navLinks}
                {!isMobile && <ThemePopover />}
            </Flex>

        </Box>
    )
}

interface MobileNavProps {
    page: Page
    navLinks: ReactNode
}

const MobileNav = (props: MobileNavProps) => {
    const {isOpen, onClose, onToggle} = useDisclosure()
    const {colors} = useThemeContext()

    return (
        <Flex justify={'center'} w={'full'} zIndex={2}>
            <Button
                textAlign={'left'}
                onClick={onToggle}
                color={colors.textOnPrimary}
                rightIcon={<FaAngleDown/>}
                variant={'ghost'}
                fontFamily={'monospace'}
                textTransform={'capitalize'}
            >
                {props.page}
            </Button>
            <Drawer placement={'top'} onClose={onClose} isOpen={isOpen}>
                <DrawerContent bg={colors.primary} transitionDuration={'medium'}>
                    <DrawerBody>
                        <VStack onClick={onClose} divider={<Divider borderColor={colors.textOnPrimary}/>}>
                            {props.navLinks}
                            <MobileTray />
                            <ThemeControls/>
                        </VStack>
                        <Center w={'full'}>
                            <IconButton
                                aria-label={'close'}
                                mt={3}
                                color={colors.textOnPrimary}
                                variant={'ghost'}
                                fontSize={20}
                                borderRadius={'100px'}
                                icon={<FaAngleUp/>}
                                onClick={onClose}/>
                        </Center>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Flex>
    )
}
