import React, {useEffect} from 'react';
import { Flex} from "@chakra-ui/react";
import {HashRouter, Route, Switch,} from "react-router-dom";
import {Navbar} from "./components/Navbar";
import {Content} from "./components/Content";
import {SiteRoute} from "./util/const";
import {DesktopTray} from "./components/Tray";
import {Footer} from "./components/Footer";
import {Home} from "./pages/Home";
import {useThemeContext} from "./context/ThemeContext";
import {useIsMobile} from "./hooks/useIsMobile";
import {Projects} from "./pages/Projects";
import {Photos} from "./pages/Photos";
import {FoodAndGarden} from "./pages/FoodAndGarden";
import {Blog} from "./pages/Blog";
import { useThemedBrowserBar } from '@owl-93/themed-browser-bar';


function App() {
    const { colors } = useThemeContext()
    const isMobile = useIsMobile()
    useThemedBrowserBar(colors.primary)

    useEffect(() => {
        document.getElementsByTagName('body')?.item(0)?.setAttribute('background-color', colors.background)
    }, [colors])

    return (
        <HashRouter>
            <Flex direction={'column'} w={'full'} height={'full'} minH='100vh'
                  bg={colors.background}
                  color={colors.text} transition={'all 200ms ease-in-out'}>
                <Navbar />
                {!isMobile && <DesktopTray /> }
                <Content >
                    <Switch>
                        <Route path={SiteRoute.PROJECTS}>
                            <Projects />
                        </Route>
                        <Route path={SiteRoute.STUFF}>
                            <Blog />
                        </Route>
                        <Route path={SiteRoute.FOOD_GARDEN}>
                            <FoodAndGarden />
                        </Route>
                        <Route path={SiteRoute.PHOTOS}>
                                <Photos />
                        </Route>
                        <Route path={SiteRoute.HOME}>
                            <Home/>
                        </Route>
                        <Route exact path={'/'}>
                            <Home/>
                        </Route>
                    </Switch>
                </Content>
                <Footer/>

            </Flex>
        </HashRouter>


    );
}

export default App;
