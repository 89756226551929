import {CloudinaryResource} from "../model/api";

export const hasTags = (tags: Set<string>, photo: CloudinaryResource): boolean => {
    return photo.tags.some((tag: string) => tags.has(tag))
    // photo.tags.forEach((t: string) => {
    //     if(tags.has(t)) {
    //         console.log(`photo ${photo.asset_id} matched filter`)
    //         return true
    //     }
    // })
    // console.log(`photo ${photo.asset_id} has no matching tag`)
    // return false
}

