import {Link as ChakraLink, LinkProps as ChakraLinkProps} from '@chakra-ui/react'
import {useThemeContext} from "../context/ThemeContext";

export type LinkProps = ChakraLinkProps

export const Link = (props: LinkProps) => {
  const {colors} = useThemeContext()

    return (
        <ChakraLink color={colors.primary} isExternal fontWeight={600} {...props} />
    )
}
