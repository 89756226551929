import {Tag as ChakraTag, TagProps as ChakraTagProps} from '@chakra-ui/react'
import {useThemeContext} from "../context/ThemeContext";

export type TagProps = ChakraTagProps

export const Tag = (props: TagProps) => {
    const { colors } = useThemeContext()

    return (
        <ChakraTag
            bg={colors.secondary}
            color={colors.textOnSecondary}
            transition={'all 200ms ease'}
            userSelect={'none'}
            {...props}
        />
    )
}
