import React, {ReactElement, useCallback} from 'react'
import {Flex, HStack, IconButton, VStack} from '@chakra-ui/react'
import {FaGithub, FaEnvelope, FaLinkedin, FaMedium} from "react-icons/fa";
import {useThemeContext} from "../context/ThemeContext";
import {useAnalytics} from "../index";
import { logEvent } from 'firebase/analytics';

interface TrayProps {
    border?: boolean
}

interface TrayItem {
    name: string,
    icon: ReactElement,
    link: string
}

const items = [
    {
        name: 'github',
        link: 'https://github.com/owl-93',
        icon: <FaGithub/>
    },
    {
        name: 'linkedin',
        link: 'https://www.linkedin.com/in/stephen-schmidt-0bb37473/',
        icon: <FaLinkedin/>
    },
    {
        name: 'medium',
        link: 'https://medium.com/@Owl_93',
        icon: <FaMedium/>
    },
    {
        name: 'email',
        link: 'mailto:stephen@theschmidt.me',
        icon: <FaEnvelope/>
    }
]

export const DesktopTray = (props: TrayProps) => {
    return (
        <Flex position={'fixed'} h={'full'} left={0} align={'center'} bg={'transparent'}>
            <VStack ml={2} py={0} spacing={6} w={'50px'}>
                {items.map((item: TrayItem, idx: number) => <TrayButton key={`link-${idx}`} {...item} size={'lg'}/>)}
            </VStack>
        </Flex>
    )
}

export const MobileTray = (props: TrayProps) => {

    return (
        <HStack py={0} spacing={6}>
            {items.map((item: TrayItem, idx: number) => <TrayButton key={`link-${idx}`} {...item} size={'sm'}/>)}
        </HStack>
    )
}


interface TrayItemProps extends TrayItem {
    size?: 'xs' | 'sm' | 'md' | 'lg'
}

const TrayButton = (props: TrayItemProps) => {
    const {colors} = useThemeContext()
    const analytics = useAnalytics()

    const click = useCallback(()=> {
        logEvent(analytics, "tray_item_clicked", {page: props.name})
        window.open(props.link)
    }, [analytics, props.name, props.link])

    return (
        <IconButton
            size={props.size ?? 'md'}
            aria-label={props.name}
            icon={props.icon}
            fontSize={20}
            color={colors.textOnPrimary}
            onClick={click}
            borderRadius={'100px'}
            border={'none'}
            variant={'outline'}
            bg={colors.primary}
            transitionDuration={'normal'}
            _hover={{
                bg: colors.secondary,
                color: colors.textOnSecondary,
            }}
        />
    )

}
