import React from 'react'
import {IconButton, Popover, PopoverContent, PopoverTrigger} from '@chakra-ui/react'
import {useThemeContext} from "../context/ThemeContext";
import {ThemeControls} from "./ThemeControls";


interface ThemePopoverProps {
    someProp?: string
}

export const ThemePopover = (props: ThemePopoverProps) => {
    const { colors, icon} = useThemeContext()

    return (
        <Popover offset={[0, 20]}>
            <PopoverTrigger >
                <IconButton
                    mx={4}
                    my={'12px'}
                    size={'lg'}
                    fontSize={'32px'}
                    borderRadius={'100px'}
                    fontFamily={'monospace'}
                    aria-label={'theme-chooser'}
                    variant={'ghost'}
                    icon={icon}
                    color={colors.textOnPrimary}
                    _hover={{bg: colors.secondary}}
                />
            </PopoverTrigger>
            <PopoverContent w={'min-content'} border={'none'} bg={'transparent'}>
                <ThemeControls p={4} borderRadius={4} bg={colors.primary}/>
            </PopoverContent>
        </Popover>

    )
}
