import React from 'react'
import { VStack} from '@chakra-ui/react'
import {Heading, Text} from '../overrides'
import {useContentWidth} from "../hooks/useContentWidth";
import {Helmet} from "react-helmet";

interface HomeAndGardenProps {
    someProp?: string
}

export const FoodAndGarden = (props: HomeAndGardenProps) => {
    const width = useContentWidth()

    return (
        <VStack w={width} p={4} spacing={10}>
            <Helmet>
                <title>Food & Garden - Stephen S</title>
                <meta name={"description"} content={"A place for my recipes and gardening ventures"}/>
            </Helmet>
            <Heading size={'xl'}>
                Starting the BBQ & planting the seeds..
            </Heading>
            <Text>
                Please check back later :)
            </Text>
        </VStack>
    )
}
