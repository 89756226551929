import {ColorMode, ThemeColors} from './themes'

export const mirkwoodDawn: ThemeColors = {
    primary: '#0F330C',
    secondary: '#288143',
    background: '#deb28d',
    tertiary: '#5A3525',
    text: '#25272c',
    textOnPrimary: '#DF9C64',
    textOnSecondary: '#25272c',
    textOnTertiary: '#FBF9EF'
}

export const mirkwoodDay: ThemeColors = {
    primary: '#1E6317',
    secondary: '#3ABB60',
    background: '#F5DECC',
    tertiary: '#5A3525',
    text: '#25272c',
    textOnPrimary: '#F5DECC',
    textOnSecondary: '#25272c',
    textOnTertiary: '#F5DECC'
}

export const mirkwoodNoon: ThemeColors = {
    primary: '#2d8223',
    secondary: '#226D38',
    background: '#FCFAF1',
    tertiary: '#5A3525',
    text: '#25272c',
    textOnPrimary: '#FCFAF1',
    textOnSecondary: '#25272c',
    textOnTertiary: '#FCFAF1'
}

export const mirkwoodDusk: ThemeColors = {
    primary: '#0F330C',
    secondary: '#7b1e36',
    background: '#072021',
    tertiary: '#7b1e36',
    text: '#FBF9EF',
    textOnPrimary: '#F5DECC',
    textOnSecondary: '#FBF9EF',
    textOnTertiary: '#FBF9EF'
}

export const mirkwoodNight: ThemeColors = {
    primary: '#233623',
    secondary: '#7b1e36',
    background: '#0e0b18',
    tertiary: '#7b1e36',
    text: '#F5DECC',
    textOnPrimary: '#F5DECC',
    textOnSecondary: '#FBF9EF',
    textOnTertiary: '#FBF9EF'
}


export const mirkwoodModes: Map<ColorMode, ThemeColors> = new Map([
    ['dawn', mirkwoodDawn],
    ['day', mirkwoodDay],
    ['noon', mirkwoodNoon],
    ['dusk', mirkwoodDusk],
    ['night', mirkwoodNight],
])
