import React from 'react'
import {Flex, VStack} from '@chakra-ui/react'
import {Divider, Text} from '../overrides'

interface FooterProps {
    someProp?: string
}

export const Footer = (props: FooterProps) => {

    return (
        <VStack w={'full'} spacing={3} my={8} grow={0}>
            <Divider />
            <Flex w='full'  justify={'space-between'} align={'center'} px={4} >
                <Text fontSize={'12px'} >Built with React</Text>
                <Text fontSize={'12px'} >© 2021 Stephen Schmidt</Text>
            </Flex>
        </VStack>

    )
}
