import { Flex} from "@chakra-ui/react";
import {ReactNode} from "react";

interface ContentProps {
    children: ReactNode
    fullWidth?: boolean
}

export const Content = (props: ContentProps) => {

    return (
        <Flex width={'full'} justify={'center'} px={['20px', '64px']} py={8} grow={1}>

            {props.children}
        </Flex>
    )
}
