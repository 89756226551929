import {QueryClient, QueryFunctionContext, QueryKey, UseQueryOptions} from "react-query";
import axios, {AxiosError, AxiosResponse} from "axios";
import {PhotoQueryResponse, PhotoTagQueryResponse} from "../model/api";

export const queryClient = new QueryClient()

const baseURL = process.env.REACT_APP_SERVER_URL
const client = axios.create({baseURL})

const defaultOptions = {
    retry: false,
    onError: (e: AxiosError) => {}
}

type SiteQueryOptions<T> = UseQueryOptions<T, AxiosError>

const queryBuilder = <T>(
    queryKey: QueryKey,
    queryFn: (key: QueryFunctionContext) => Promise<AxiosResponse<T>>,
    otherOptions?: UseQueryOptions<T, AxiosError>
): UseQueryOptions<T, AxiosError> => ({
    queryKey,
    queryFn: (key: QueryFunctionContext) => queryFn(key).then((r: AxiosResponse<T>) => r.data),
    ...defaultOptions,
    ...otherOptions
})



//Get photos tags
export const tagsQueryKey: QueryKey = "/photos/tags"

export const getPhotoTagsQuery = (): SiteQueryOptions<PhotoTagQueryResponse> =>
    queryBuilder(tagsQueryKey, tagsQuery, {refetchOnWindowFocus: true})

const tagsQuery = async (ctx: QueryFunctionContext): Promise<AxiosResponse<PhotoTagQueryResponse>> =>
    await client.get('/photos/tags')


// Get all photos
export const getAllPhotosKey: QueryKey = '/photos'

export const getPhotosQuery = (): SiteQueryOptions<PhotoQueryResponse> =>
    queryBuilder(getAllPhotosKey, allPhotosQuery, {refetchOnWindowFocus: false})

const allPhotosQuery = async (ctx: QueryFunctionContext): Promise<AxiosResponse<PhotoQueryResponse>> =>
    await client.get('/photos/')


//Get Photos by tag
export const getPhotosByTagQueryBaseKey: QueryKey = "/photos"

export const getPhotosByTagQuery = (key: QueryKey = getPhotosByTagQueryBaseKey): SiteQueryOptions<PhotoQueryResponse> =>
    queryBuilder(key, photoQuery, { refetchOnWindowFocus: false})

const photoQuery = async (ctx: QueryFunctionContext): Promise<AxiosResponse<PhotoQueryResponse>> =>
    await client.get(`/photos/${ctx.queryKey[1]}`)

