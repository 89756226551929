import React from 'react'
import { VStack} from '@chakra-ui/react'
import {Heading, Link, Text} from "../overrides";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {useContentWidth} from "../hooks/useContentWidth";
import {Helmet} from "react-helmet";

interface BlogProps {
    someProp?: string
}

export const Blog = (props: BlogProps) => {
    const width = useContentWidth()

    return (
        <VStack w={width} p={4} spacing={10}>
            <Helmet>
                <title>Blog - Stephen S</title>
                <meta name={"description"} content={"Articles, blogs, projects, and other stuff I like and recommend or am saving"}/>
            </Helmet>
            <Heading size={'xl'}>
                I'm speechless!
            </Heading>
            <Text>
                I'm still working on this section, in the meantime please feel free to check out{' '}
                <Link href={'https://medium.com/@Owl_93'}> my medium page <ExternalLinkIcon/> </Link>
            </Text>
        </VStack>
    )
}
